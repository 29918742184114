import React from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

import './../outcomes.css';
import oplogo from './../opinionpanellogo.png';
import screenoutImage from './../screenout.png';

const Return = () => {

    let { projectId, panelistId, outcome } = useParams();
    const [state, setState] = React.useState({points: 0});

    React.useEffect(()=>{
        (async () => {
            const result = await (await axios.post(`/api/return/${panelistId}/${projectId}`)).data;
            setState({points: result.reward});            
        })();
    }, []);

    return (
        <React.Fragment>
            <div id="survey" className="survey-container">
                <div className="survey-section">
                    <div className="page-header"></div>
                    </div>
                <div className="survey-section">
                    <div className="survey-body">
                        <div className="survey-header">
                            <div className="logo logo-middle">
                                <img src={oplogo} className="logo-image"
                                        alt="Opinion Panel"/>
                            </div>
                        </div>
                        <div className="exit-message">
                            <h1 className="exit-message-header">Survey Completed</h1>
                            <p className="exit-message-text">

                                {outcome === 'complete' && (

                                <div className="final-page-header">
                                    <p>That's it - you've finished! Thank you for taking part. You earned&nbsp;
                                            {state.points} points worth £{state.points / 100} in shopping vouchers.
                                    </p><br/>

                                    <p>Please note that it may take a few minutes for your points to update on your
                                        profile page. But don’t worry, they’re on their way to you. If you don’t see
                                        your new total after a few minutes, try refreshing the page or if you’d like
                                        further support, please email us at:
                                        <a href="mailto:support@opinionpanel.co.uk">support@opinionpanel.co.uk</a></p>

                                    <p>Please click <a href="http://www.opinionpanel.co.uk/category/opinion/">here
                                        to go back to The OpinionPanel Community site</a> and find out what the rest
                                        of the community has been talking about, or log into your account to update
                                        your details.</p><br/>
                                </div>

                                )}

                                {['tentative', 'term', 'oq', 'security', 'nosurveys', 'blocked'].includes(outcome) && (

                                    <React.Fragment>

                                        <div className="final-page-header">

                                            {['tentative', 'term', 'security', 'blocked'].includes(outcome) && (
                                                <p><strong>Sorry, this survey isn’t right for you 🙁 Thanks for taking the time to do it.</strong></p>
                                            )}

                                            {['oq', 'nosurveys'].includes(outcome) && (
                                                <p><strong>Sorry, other members got there before you 🙁 Thanks for taking the time to do it.</strong></p>
                                            )}

                                            <p><strong>You won’t be able to complete this survey and earn the points, but you may win £50 in vouchers!</strong></p>
                                        </div><br />
                                        <div className="screenout">
                                            <p>Based on your answers, we worked out that our survey isn’t right for you, or by
                                                the time you reached this stage, we have already achieved the number of answers
                                                needed, from people who match the profile.</p>
                                            <p>We know this can be frustrating, which is why we have entered you into our monthly
                                                prize draw to win £50 in vouchers! Keep an eye out for an email
                                                from us to see if you’ve won!</p><br />
                                            <p>If you do have any questions or comments, please get in touch with us via the support
                                                inbox: <a href={`mailto:support@opinionpanel.co.uk?subject=Question about survey ${projectId}`}>support@opinionpanel.co.uk</a> and quote the survey reference,
                                                which was in the email invite you received, or on the MySurveys dashboard.</p><br />
                                            <p>Thanks again for taking part 😊</p><br/>
                                        </div>

                                        <div align="center">
                                            <img alt='' style={{'max-width': 90+'%', width: 270, height: 270}} src={screenoutImage} />
                                        </div><br />

                                    </React.Fragment>

                                )}

                                <br/>
                                <div align="center">
                                    <a href="http://www.opinionpanel.co.uk/category/opinion/" className="finish">Finish</a>
                                </div>
                                <div className="survey-progress-bar progress-bottom" title="progress bar - 100% complete">
                                    <div className="progress-box-outer">
                                        <span className="progress-box-completed" style={{width: 100 + '%'}}></span>
                                    </div>
                                    <div className="progress-text">
                                        <span className="screen-readers-only">progress bar</span>
                                        100%
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="survey-section">
                        <div className="footer">
                            <div>
                                <a className="footer-button"
                                    href="mailto:support@opinionpanel.co.uk?subject=Support%20request"
                                    target="_blank" rel="noreferrer">Support</a>
                            </div>
                            <div>
                                <a className="footer-button"
                                    href="https://www.opinionpanel.co.uk/external-terms-conditions-and-privacy-policy"
                                    target="_blank" rel="noreferrer">Privacy</a>
                            </div>
                        </div></div>
                </div>
                <div id="detectBreakpoint"></div>
            </div>

        </React.Fragment>
    );

}

export default Return;