import React from 'react';
import * as Moment from 'moment';
import 'material-icons/iconfont/material-icons.css';

import './../admin.scss';

const Admin = () => {

    const [adminInfo, setAdminInfo] = React.useState({ userName: '' });
    const [usageSummary, setUsageSummary] = React.useState();
    const [dots, setDots] = React.useState(0);

    React.useEffect(() => {
        if (!usageSummary) {
            var timer = setTimeout(() => {
                setDots((dots + 1) % 5);
            }, 500);
        }

        return () => {
            clearInterval(timer);
        };
    }, [dots]);

    React.useEffect(() => {
        (async () => {
            const result = await fetch('/api/manage/admininfo');
            const adminInfo = await result.json();
            setAdminInfo(adminInfo);
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            const result = await fetch('/api/manage/usagesummary');
            const usageSummary = await result.json();
            setUsageSummary(usageSummary);
        })();
    }, []);

    return <>
        <nav className="container">
            <ul>
                <li>Youth Sight Cint Integration</li>
            </ul>
            <ul>
                <li><a href="/logout">Logout {adminInfo.userName}</a></li>
            </ul>
        </nav>

        <main className="container">
            {!usageSummary &&
                <p><span class="material-icons-outlined">timer</span>Loading summary. Please wait{".".repeat(dots + 1)}</p>}
            {usageSummary &&
                <>
                    <div>
                        <div className='summaryHeader'>Data for dates between <b>{Moment(usageSummary.minDate).format("DD/MM/YYYY")}</b> and <b>{Moment(usageSummary.maxDate).format("DD/MM/YYYY")}</b></div>
                        <div className='summaryContainer'>
                            <div>
                                <span>Total panelists</span>
                                <span>{usageSummary.totalPanelists.toLocaleString()}</span>
                            </div>
                            <div>
                                <span>Total points awarded</span>
                                <span>{usageSummary.totalPoints.toLocaleString()}</span>
                            </div>
                            <div>
                                <span>Total tentative</span>
                                <span>{usageSummary.outcomeSummary.tentative.toLocaleString()}</span>
                            </div>
                            <div>
                                <span>Total completes</span>
                                <span>{usageSummary.outcomeSummary.complete.toLocaleString()}</span>
                            </div>
                            <div>
                                <span>Total screen outs</span>
                                <span>{usageSummary.outcomeSummary.disqualified.toLocaleString()}</span>
                            </div>
                            <div>
                                <span>Total quota outs</span>
                                <span>{usageSummary.outcomeSummary.quota.toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                    <span class="material-icons">download</span><a href="/api/manage/usagereport">Download usage report</a>
                </>
            }
        </main>
    </>
}

export default Admin;