import axios from 'axios';
import React from 'react';
import { useParams } from "react-router-dom";
import oplogo from "../opinionpanellogo.png";

const Start = () => {

    let { projectId, panelistId } = useParams();

    const [message, setMessage] = React.useState('Redirecting to chosen survey... please wait');

    React.useEffect(() => {
        (async () => {

            try {
                const result = await (await axios.post(`/api/linkclick/${panelistId}/${projectId}`)).data;

                if (result.cintLink) {
                    window.location.replace(result.cintLink);
                }
            }
            catch (e) {
                setMessage('There was an error starting this survey. Please try again later.');
            }
        })();
    }, []);

    return (
        <div id="survey" className="survey-container">
            <div className="survey-section">
                <div className="page-header"></div>
            </div>
            <div className="survey-section">
                <div className="survey-body">
                    <div className="survey-header">
                        <div className="logo logo-middle">
                            <img src={oplogo} className="logo-image"
                                alt="Opinion Panel" />
                        </div>
                    </div>
                    <div className="final-page-header">
                            <br />
                            <div align="center">
                                {message}
                            </div>
                     </div>
                </div>
            </div>
            <div id="detectBreakpoint"></div>
        </div>
    )
}

export default Start;