import React from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from "react-router-dom";

import Start from './components/start';
import Return from './components/return';
import Admin from './components/admin';

function App() {

    return <Router>
        <Routes>
            <Route exact path="/" element={<Navigate replace to="/admin" />} />
            <Route exact path="/start/:projectId/:panelistId" element={<Start />} />
            <Route exact path="/return/:outcome/:projectId/:panelistId/:ssi" element={<Return />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="*" element={<div>Not found</div>} />
        </Routes>
    </Router>
}

export default App;